import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: none;
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--green);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    'Dart/Flutter',
    'Java/Kotlin',
    'JavaScript/TypeScript',
    'Python',
    'SQL',
    'Spring Boot',
    'Hibernate',
    'SAP',
    'Oracle Database',
    'MongoDB',
    'AT Protocol',
    'Bluesky API',
    'OAuth 2.0 PKCE',
    'BI Tools',
  ];

  const birthYear = 1993;
  const birthMonth = 9;
  const birthDay = 24;

  const calculateAge = (birthYear, birthMonth, birthDay) => {
    const today = new Date();
    const birthDate = new Date(birthYear, birthMonth - 1, birthDay);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const age = calculateAge(birthYear, birthMonth, birthDay);

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              Hello! I am{' '}
              <a href="https://github.com/myConsciousness">
                <b>Shinya Kato</b> a.k.a myConsciousness
              </a>
              . I am {age} years old and I enjoy creating things.{' '}
              <b>My interest in programming started back in 2007</b>, when I was almost 13 years
              old, when I decided to try editing custom themes on some blogging services. I learned
              simple <a href="https://en.wikipedia.org/wiki/HTML">HTML</a> &amp;{' '}
              <a href="https://en.wikipedia.org/wiki/CSS">CSS</a>, and once I started using{' '}
              <a href="https://en.wikipedia.org/wiki/JavaScript">JavaScript</a>, I felt as if I had
              my own magic like a hacker in Matrix!
            </p>

            <p>
              Fast-forward to today, as a <a href="https://www.java.com">Java developer</a>,{' '}
              <b>I have developed and maintained systems for many large Japanese companies</b>.
              Then, after several years of system development as a company employee, I started
              working as a <b>freelance developer</b>.
            </p>

            <p>
              I have been developing with <a href="https://dart.dev">Dart</a>/
              <a href="https://flutter.dev">Flutter</a> since early 2020 and have{' '}
              <b>developed and maintained many OSS packages</b> for these technologies. And
              recently, I have been enthusiastic about{' '}
              <a href="https://github.com/myConsciousness/atproto.dart">
                developing packages of AT Protocol and Bluesky things
              </a>
              .
            </p>

            <p>
              I also write articles on programming for{' '}
              <a href="https://zenn.dev/kato_shinya">Zenn.dev</a>,{' '}
              <a href="https://dev.to/shinyakato">DEV.to</a> and etc.
            </p>

            <p>And here are a few technologies I’ve been working with recently:</p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
